import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Layout from "./components/Layout"
import SEO from "./components/Seo"
import PostCard from "./components/PostCard"
import ConsoleAbout from "./components/ConsoleAbout"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }) => {
  const siteTitle = "cp - portfolio"

  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO
        title="All posts"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      {/* <Bio /> */}
      {"data.site.siteMetadata.description" && (
        <header className="page-head">
          <h3 style={{ margin: "0" }}>Hello,</h3>
          <h2 className="page-head-title">
            <span>I am Chandraprakash,</span>
            <br />
            <span style={{ opacity: "75%" }}>
              a freelace web designer and developer. I like building simple,
              beautiful user experiences.
            </span>
          </h2>
          <h3>
            Checkout my{" "}
            <a href="#projects" style={{ color: "#19647e" }}>
              side projects
            </a>{" "}
            bellow
          </h3>
        </header>
      )}
      <ConsoleAbout />
      <section id="projects">
        <h2
          style={{
            color: "#19647e",
            textAlign: "center",
            fontSize: "64px",
            textDecoration: "underline",
            marginBottom: "5vh",
          }}
        >
          Projects
        </h2>
        <div className="post-feed">
          {data.allContentfulPost.edges.map(({ node }) => {
            postCounter++

            return (
              <PostCard
                coverImage={node.cover.fixed.src}
                slug={node.slug}
                title={node.title}
                key={node.slug}
                count={postCounter}
                postClass={`post`}
              />
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPost {
      edges {
        node {
          slug
          title
          id
          pictures {
            gatsbyImageData
            fluid {
              src
            }
          }
          cover {
            fixed(quality: 80) {
              src
            }
          }
        }
      }
    }
  }
`
BlogIndex.propTypes = {
  data: PropTypes.node,
}

export default BlogIndex
