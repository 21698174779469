import React from "react"

function ConsoleAbout() {
  const statements = [
    {
      input: "Chandraprakash.currentLocation",
      return: '"Ajmer, Rajasthan"',
    },
    {
      input: "Chandraprakash.contactInfo",
      return:
        '["<a href="mailto:cpsuthar111@gmail.com">cpsuthar111@gmail.com</a>", "<a rel="noopener" href="https://github.com/pintio">github</a>"]',
    },
    {
      input: "Chandraprakash.resume",
      return:
        '"<a rel="noopener" href="/resume.pdf" target="_blank">Chandraprakash.pdf</a>"',
    },
    {
      input: "Chandraprakash.interests",
      return: '["design", "photography", "music", "cycling"]',
    },
    {
      input: "Chandraprakash.education",
      return:
        '"B.Tech. Electronics and comm. Engineering - Bhagwant University, Ajmer"',
    },
    {
      input: "Chandraprakash.skills",
      return:
        '["JavaScript", "React", "TailwindCSS", "NodeJS", "UI Design", "Adobe Creative Suite"]',
    },
  ]

  return (
    <div className="about-container">
      <div className="terminal">
        <div className="terminal-header">
          <div className="header-button red" />
          <div className="header-button yellow" />
          <div className="header-button green" />
        </div>
        <div className="terminal-window">
          <Statements statements={statements} />
        </div>
      </div>
    </div>
  )
}

function Statements({ statements }) {
  return (
    <div>
      {statements.map((statement, index) => {
        return <Statement statement={statement} key={index} />
      })}
      <div className="statement">
        <div className="input-statement">
          <span>&nbsp;</span>
        </div>
      </div>
    </div>
  )
}

function Statement({ statement }) {
  return (
    <div className="statement">
      <div className="input-statement">{statement.input}</div>
      <div
        className="return-statement"
        dangerouslySetInnerHTML={{ __html: statement.return }}
      />
    </div>
  )
}

export default ConsoleAbout
