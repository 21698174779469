import React from "react"
import { Link } from "gatsby"

function PostCard(props) {
  console.log("look herer", props.coverImage)

  return (
    <article
      className={`post-card ${props.count % 3 === 0 && `post-card-large`} ${
        props.postClass
      } ${props.coverImage ? `with-image` : `no-image`}`}
      style={
        props.coverImage && {
          backgroundImage: `url(${props.coverImage})`,
        }
      }
    >
      <Link to={props.slug} className="post-card-link">
        <div className="post-card-content">
          <h2 className="post-card-title">{props.title || props.slug}</h2>
        </div>
      </Link>
    </article>
  )
}

export default PostCard
